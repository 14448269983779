<template>

    <div class="w-full h-full flex flex-col relative overflow-hidden px-2">

        <div class="h-full module flex flex-col">

            <div class="h-8 flex flex-row justify-center items-center relative">

                <span class="text-sm font-semibold text-aux" v-if="slStore">{{slStore.name}}</span>

                <div class="absolute top-0 right-0 h-full w-8 flex flex-col justify-center items-center" @click="filtersOpen = !filtersOpen">
                    <i class="mdi " :class="{'mdi-dots-vertical':!filtersOpen, 'mdi-close':filtersOpen}"></i>
                </div>

            </div>

            <div class="overflow-hidden my-2 flex flex-row justify-start items-center" :class="{'h-0': !filtersOpen, 'h-12': filtersOpen}" style="transition: all .3s">

                <div class="h-full w-1/2 flex flex-row justify-start items-center p-1">

                    <div class="h-full rounded-md bg-body w-full  flex flex-row">

                        <div class="h-full w-1/2 flex flex-col justify-center items-center relative p-2 cursor-pointer" @click="switch1 = 'Hours'">

                            <div class="h-full w-full flex flex-col justify-center items-center relative">

                                <span class="font-semibold z-20 text-xxs" :class="{'text-font-gray': switch1 != 'Hours', 'font-white': switch1 == 'Hours'}">Hours</span>

                                <div v-if="switch1 == 'Hours'" class="h-full w-full rounded-md bg-font-gray-15 absolute z-10 shadow-md"></div>

                            </div>

                        </div>

                        <div class="h-full w-1/2 flex flex-col justify-center items-center relative p-2 cursor-pointer" @click="switch1 = 'Fees'">

                            <div class="h-full w-full flex flex-col justify-center items-center relative">

                                <span class="font-semibold z-20 text-xxs" :class="{'text-font-gray': switch1 != 'Fees', 'font-white': switch1 == 'Fees'}">Fees</span>

                                <div v-if="switch1 == 'Fees'" class="h-full w-full rounded-md bg-font-gray-15 absolute z-10 shadow-md"></div>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="h-full w-1/2 flex flex-row justify-start items-center p-1">

                    <div class="h-full rounded-md bg-body w-full flex flex-row">

                        <div class="h-full w-1/2 flex flex-col justify-center items-center relative p-2 cursor-pointer" @click="switch2 = 'Purchased'">

                            <div class="h-full w-full flex flex-col justify-center items-center relative">

                                <span class="font-semibold z-20 text-3xs" :class="{'text-font-gray': switch2 != 'Purchased', 'font-white': switch2 == 'Purchased'}">Purchased</span>

                                <div v-if="switch2 == 'Purchased'" class="h-full w-full rounded-md bg-font-gray-15 absolute z-10 shadow-md"></div>

                            </div>

                        </div>

                        <div class="h-full w-1/2 flex flex-col justify-center items-center relative p-2 cursor-pointer" @click="switch2 = 'Sold'">

                            <div class="h-full w-full flex flex-col justify-center items-center relative">

                                <span class="font-semibold z-20 text-xxs" :class="{'text-font-gray': switch2 != 'Sold', 'font-white': switch2 == 'Sold'}">Sold</span>

                                <div v-if="switch2 == 'Sold'" class="h-full w-full rounded-md bg-font-gray-15 absolute z-10 shadow-md"></div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div class="flex-1 overflow-hidden -mx-3">

                <swiper ref="mySwiper" :options="swiperOptions" class="h-full w-full"
                :auto-destroy="true"
                :delete-instance-on-destroy="true"
                :cleanup-styles-on-destroy="true">
                <div class="swiper-pagination" slot="pagination"></div>

                <swiper-slide class="h-full w-full overflow-hidden no-scrollbar" style="width: 90%">

                    <div class="h-full w-full rounded-lg bg-filters rounded-lg p-2 flex flex-col relative overflow-auto mobile-scrollbar">

                        <loader :loading="loadingSL"></loader>

                        <div v-if="elementsSL && elementsSL.graph_sl.graph_sl_purchased.length > 0 && elementsSL.graph_sl.graph_sl_sold.length > 0" class="h-auto">

                            <div class="h-8 flex flex-row justify-center items-center">
                                <span class="text-sm font-semibold text-font-gray mr-auto">SL</span>
                            </div>

                            <div v-if="elementsSL" class="h-72 flex flex-col justify-center itmes-center mb-6">
                                <chart-dependency-wheel name="SL" v-if="switch1 == 'Hours' && switch2 == 'Purchased'" :mobile="true" :data="elementsSL.graph_sl.graph_sl_purchased" :chartId="'SLChart'"/>
                                <chart-dependency-wheel name="SL" v-if="switch1 == 'Hours' && switch2 == 'Sold'" :mobile="true" :data="elementsSL.graph_sl.graph_sl_sold" :chartId="'SLChart'"/>
                                <chart-dependency-wheel name="SL" v-if="switch1 == 'Fees' && switch2 == 'Purchased'" :mobile="true" :data="elementsSL.graph_sl.graph_sl_purchased_fees" :chartId="'SLChart'"/>
                                <chart-dependency-wheel name="SL" v-if="switch1 == 'Fees' && switch2 == 'Sold'" :mobile="true" :data="elementsSL.graph_sl.graph_sl_sold_fees" :chartId="'SLChart'"/>
                            </div>

                            <div class="h-auto w-full flex-none flex flex-row">

                                <div class="w-1/3 flex-none"></div>

                                <div class="w-2/3 flex flex-row justify-between items-center">

                                    <div class="h-full w-1/2 flex flex-col justify-center items-center px-2">
                                        <span class="text-3xs text-center">Own Engagement</span>
                                    </div>
                                    <div class="h-full w-1/2 flex flex-col justify-center items-center px-2">
                                        <span v-if="switch2 == 'Purchased'" class="text-3xs text-center">Purchased to others</span>
                                        <span v-if="switch2 == 'Sold'" class="text-3xs text-center">Sold to others</span>
                                    </div>

                                </div>

                            </div>

                            <div v-for="(el,index) in dataTableSL" :key="index" class="h-auto w-full flex-none flex flex-row">

                                <div class="h-8 w-1/3 flex flex-col justify-center items-start flex-none">
                                    <span class="font-semibold text-3xs truncate text-font-gray" :style="'color:'">{{el[0]}}</span>
                                </div>

                                <div class="h-8 w-2/3 flex flex-row justify-between items-center">

                                    <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                        <span v-if="el[2] && el[2]%2" class="text-3xs">{{el[2] | numberFormat(2)}}%</span>
                                        <span v-else class="text-3xs">-</span>
                                    </div>

                                    <div v-if="switch2 == 'Purchased'" class="h-full w-1/2 flex flex-col justify-center items-center">
                                        <span class="text-3xs">{{elementsSL.graph_sl.summary_sl.purchased_other[index][2] | numberFormat(2)}}%</span>
                                    </div>

                                    <div v-if="switch2 == 'Sold'" class="h-full w-1/2 flex flex-col justify-center items-center">
                                        <span class="text-3xs">{{elementsSL.graph_sl.summary_sl.sold_other[index][2] | numberFormat(2)}}%</span>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div v-else-if="!loadingSL" class="h-full flex flex-col justify-center items-center">
                            <span class="module-title text-font-gray text-xs">No data</span>
                        </div>

                    </div>

                </swiper-slide>

                <swiper-slide class="h-full w-full overflow-hidden no-scrollbar" style="width: 90%">

                    <div class="h-full w-full rounded-lg bg-filters rounded-lg p-2 flex flex-col relative overflow-auto mobile-scrollbar">

                        <loader :loading="loadingSMU"></loader>

                        <div v-if="elementsSMU && elementsSMU.graph_competency.length > 0" class="h-auto">

                            <div class="h-8 flex flex-row justify-center items-center">
                                <span class="text-sm font-semibold text-font-gray mr-auto">SMU</span>
                            </div>

                            <div class="h-72 flex flex-col justify-center itmes-center mb-6">
                                <chart-dependency-wheel name="SMU" v-if="switch1 == 'Hours'" :mobile="true" :data="elementsSMU.graph_competency" :chartId="'SMUChart'"/>
                                <chart-dependency-wheel name="SMU" v-if="switch1 == 'Fees'" :mobile="true" :data="elementsSMU.graph_competency_fees" :chartId="'SMUChart'"/>
                            </div>

                            <div class="h-auto w-full flex-none flex flex-row">

                                <div class="w-1/3 flex-none"></div>

                                <div class="w-2/3 flex flex-row justify-between items-center">

                                    <div class="h-full w-1/2 flex flex-col justify-center items-center px-2">
                                        <span class="text-3xs text-center">Own Engagement</span>
                                    </div>
                                    <div class="h-full w-1/2 flex flex-col justify-center items-center px-2">
                                        <span v-if="switch2 == 'Purchased'" class="text-3xs text-center">Purchased to others</span>
                                        <span v-if="switch2 == 'Sold'" class="text-3xs text-center">Sold to others</span>
                                    </div>

                                </div>

                            </div>

                            <div v-for="(el,index) in dataTableSMU" :key="index" class="h-auto w-full flex-none flex flex-row">

                                <div class="h-8 w-1/3 flex flex-col justify-center items-start flex-none">
                                    <span class="font-semibold text-3xs text-left text-font-gray" :style="'color:'">{{el[0]}}</span>
                                </div>

                                <div class="h-8 w-2/3 flex flex-row justify-between items-center">

                                    <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                        <span class="text-3xs">{{el[2] | numberFormat(2)}}%</span>
                                    </div>

                                    <!-- <div class="h-full w-1/2 flex flex-col justify-center items-center">
                                        <span class="text-3xs">{{elementsSMU.summary_competency.purchased_other[index][2] | numberFormat(2)}}%</span>
                                    </div> -->

                                    <div v-if="switch2 == 'Purchased'" class="h-full w-1/2 flex flex-col justify-center items-center">
                                        <span class="text-3xs">{{elementsSMU.summary_competency.purchased_other[index][2] | numberFormat(2)}}%</span>
                                    </div>

                                    <div v-if="switch2 == 'Sold'" class="h-full w-1/2 flex flex-col justify-center items-center">
                                        <span class="text-3xs">{{elementsSMU.summary_competency.sold_other[index][2] | numberFormat(2)}}%</span>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div v-else-if="!loadingSMU" class="h-full flex flex-col justify-center items-center">
                            <span class="module-title text-font-gray text-xs">No data</span>
                        </div>

                    </div>

                </swiper-slide>

                </swiper>

            </div>

        </div>

    </div>

</template>

<script>

import { state } from '@/store';

export default {
    data() {
        return {
            loadingSL: true,
            loadingSMU: true,
            filtersOpen: false,
            switch1:'Hours',
            switch2: 'Purchased',
            elementsSL: false,
            elementsSMU: false,
            slIdStore: null,
            slStore: null,
            swiperOptions: {
                slidesPerView: 'auto',
                spaceBetween: 10,
                centeredSlides: true,
            }
        }
    },
    methods: {
        onPanUp() { this.filters = false },
        onPanDown() { this.filters = true },
        add(option) {
            if (this.activeOption.length > 0) {
                let error = false
                for (let index = 0; index < this.activeOption.length; index++) {
                    if (option === this.activeOption[index]) {
                        error = true
                        this.activeOption.splice(index, 1)
                    }
                }
                if (!error) { this.activeOption.push(option) }
            }
            else { this.activeOption.push(option) }
        },
        load() {
            this.getSL(),
            this.getSMU()
        },
        getSL() {
            this.axios.get('inter_intra/graph_sl', { params: {...this.params, sl_zeus: [this.$route.params.idSL]} }).then(response => {
                this.elementsSL = false
                this.elementsSL = response.data.data;
                this.loadingSL = false
            })
        },
        getSMU() {
            this.slIdStore = this.$route.params.idSL;
            if(this.sl === null) {
                this.axios.get('inter_intra/sl', { params: { ...this.params, sl_zeus: [this.$route.params.idSL] }})
                    .then(response => {
                        this.slStore = response.data.data[0];
                    })
            } else {
                this.slStore = this.sl;
            }

            this.axios.get('inter_intra/graph_competency', { params: { ...this.params, sl_zeus: [this.$route.params.idSL] }})
                .then(response => {
                    //var arr = _.values(obj);
                    this.elementsSMU = response.data.data.graph_competency
                    this.loadingSMU = false
                })
        },
        goBack(){
            this.$router.push( {name:'InterIntraMobileSL'} )
        }
    },
    computed: {
        params() {
            return state.globalParams 
        },
        sl(){
            return state.interIntraSL
        },
        dataTableSL(){

            if(this.elementsSL){

                if(this.switch1 == 'Hours'){

                    if(this.switch2 == 'Purchased'){

                        return this.elementsSL.graph_sl.summary_sl.purchased

                    } else {

                        return this.elementsSL.graph_sl.summary_sl.sold

                    }

                } else {

                    if(this.switch2 == 'Purchased'){

                        return this.elementsSL.graph_sl.summary_sl_fees.purchased

                    } else {

                        return this.elementsSL.graph_sl.summary_sl_fees.sold

                    }

                }

            }

        },
        dataTableSMU(){

            if(this.elementsSMU){

                if(this.switch1 == 'Hours'){

                    if(this.switch2 == 'Purchased'){

                        return this.elementsSMU.summary_competency.purchased

                    } else {

                        return this.elementsSMU.summary_competency.sold

                    }

                } else {

                    if(this.switch2 == 'Purchased'){

                        return this.elementsSMU.summary_competency_fees.purchased

                    } else {

                        return this.elementsSMU.summary_competency_fees.sold

                    }

                }

            }
        }
    },
    watch: {
        params() {
            this.load() 
        }
    },
    mounted() {
        if(this.params) {
            this.load();
        }
    }
}
</script>